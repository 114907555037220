import React from 'react'
import { FaFacebookF } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { IconContext } from 'react-icons'


function SocialIcons(props) {
    return (

        <IconContext.Provider value={{ size: props.size || "16px", color: props.color || "#000000"}}>
            <div className="d-flex justify-content-between align-content-center social-icons-block">
                <div className="facebook">
                    <a href="https://www.facebook.com/VoiceOfChristInHaiti/">
                        <FaFacebookF />
                    </a>
                </div>
                <div className="twitter">
                    <a href="https://twitter.com/Vocih">
                        <FaTwitter />
                    </a>
                </div>
                <div className="instagram">
                    <a href="https://www.instagram.com/vocih_/">
                        <FaInstagram />
                    </a>
                </div>
            </div>
        </IconContext.Provider>
    )
}

export default SocialIcons
