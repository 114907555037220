import React from 'react';
import LogoNav from './LogoNav';
import { FiArrowDown } from 'react-icons/fi'
import { IconContext } from 'react-icons';
import { HashLink as Link } from 'react-router-hash-link';
import { Tween } from 'react-gsap';




function HomeHeader() {
    return (
    <section id="home-header">
        <div className="tint-overlay"></div>
        <div className="header-wrap">
            <div className="logo-nav">
                <LogoNav />
            </div>
            <div className="boxed-wrapper header-content d-flex align-items-end">

                <Tween from={{ opacity: '0', y:"-200px"}} duration={1.2} ease="back.out(1.7)">
                    <div className="header-inner-content">
                    <div className="title">
                        <h1>
                            Establishing the Voice<br/> of Christ in Haiti
                        </h1>
                    </div>
                        
                    <div className="copy">
                            <p>
                            Taking back the country of Haiti for Christ through education, evangelism, and healthcare in rural communities of Haiti
                            </p>
                    </div>
                    <div className="learn-more-btn">

                                <Link to="/#vocih-pillars"
                                className="d-flex align-items-center justify-content-between button primary large"
                                    scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                                        Learn More
                                            <div className="icon">
                                            <IconContext.Provider value={{ size:"24px", color:"#FFFFFF" }}>
                                            <FiArrowDown />
                                            </IconContext.Provider>
                                            </div>
                                       



                                </Link>
                    </div>
                </div>
                </Tween>
           
            </div>
            
        </div> 
    </section>
        
      
    )
}










export default HomeHeader




