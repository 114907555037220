import React, { Component } from 'react'
import Lottie from 'react-lottie';
import SuccessIcon from '../../images/success-icon.json';


class Subscribe extends Component{


    state = {
        email: "",
        hpname:"",
        emailerror: "",
        mcstatus: "",
        atstatus: "",
        sending: null
    }

     handleFormInput = (e) => 
    {
        e.preventDefault();
        this.setState(
            {
                emailerror : "",
                [e.target.name] : e.target.value
            });
    }



     handleFormSubmit = (e) =>
    {
        e.preventDefault();
 
        // check if email field was empty
        if(this.state.email === "") {
            this.setState(prevState => ({
                ...prevState,
                emailerror : "Email Address is required"
            }))
            return false
        }

        //check if email address was entered correctly
        var regex = /\S+@\S+\.\S+/;
        if(this.state.email.length > 0 && !regex.test(this.state.email)) {
            this.setState(prevState => ({
                ...prevState,
                emailerror : "Your email address isn't right"
            }))
            return false
        }



        if(this.state.emailerror === "") { // no error on email address

            this.setState({ sending : true })


// Send data to mailchimp
// ==========================================

        //setup data object for mailchimp
        const dataToMailChimp = {
            members : 
            [
                {
                    email_address : this.state.email, 
                    status : 'subscribed'
                }
            ]
        }

        const postData = JSON.stringify(dataToMailChimp)
        fetch(`https://cors-anywhere.herokuapp.com/https://us10.api.mailchimp.com/3.0/lists/${process.env.REACT_APP_MAILCHIMP_ID}`,
        {
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization : `auth ${process.env.REACT_APP_MAILCHIMP_API_KEY}`
            },
            body : postData
        
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                ...prevState,
                mcstatus : "success",
            }))
        })
        .catch(err => {
            this.setState(prevState => ({
                ...prevState,
                 mcstatus: "falied"
            }))
            console.log(err)
        })
// ============ END =========================

// Send data to Airtable
// ==========================================

        // setup data object to airtable
        const dataToAirtable = {
        "records": [
            { "fields" :
                {
                    "Email Address": `${this.state.email}`
                }
            }
        ]
        }
    
        const postAirtableData = JSON.stringify(dataToAirtable);
    
        // send to Airtable
        fetch(`https://cors-anywhere.herokuapp.com/https://api.airtable.com/v0/app7cNniwL76IHtfS/Subscribers?api_key=${process.env.REACT_APP_AIRTABLE_API_KEY}`,
        {
            method : "POST",
            headers: {
            'Content-Type' : 'application/json',
            Authorization : `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`
        },
        body : postAirtableData
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
            ...prevState,
            atstatus : "success",
            sending: false
            }))
        })
        .catch(err => {
        this.setState(prevState => ({ ...prevState, atstatus : "failed" }))
        console.log(err)
        })
        
    }  
}




    render ()
    {

        const LottieOptions = {
            loop: false,
            autoplay: true,
            animationData: SuccessIcon,
            renderSettings : {
                preserveAspectRatio : "xMidYMid slice"
            }
        };
          return (
        <section id="subscribe">
             <div className="boxed-wrapper subscribe-container">
                

         
                    <div className="subscribe-wrap">

                        <div className="justify-content-between subscribe-content">
                            <div className="text">
                            <h3>
                                Subscribe to our eNewsletter
                                </h3> 
                                <p>Signup for news and updates</p>
                            </div>
                            <div className="form">
                                <form onSubmit={ this.handleFormSubmit }>


                                    <div className="status-messages d-flex align-items-center">

                                        {
                                            this.state.mcstatus === "failed" ? (
                                                <p className="email-error">
                                                Sorry, unable to subscribe you at this time.
                                                </p>
                                                ) : (null)
                                        }
                                        
                                        {
                                            this.state.mcstatus === "success" ? (
                                                <div className="success d-flex align-items-center">
                                                    <div className="icon">
                                                        <Lottie
                                                        options = {LottieOptions}
                                                        height = {24}
                                                        width = {24} />
                                                    </div>
                                                    <div className="msg">
                                                    <p>
                                                        Thank You for subscribing! We’ll be in touch!
                                                    </p>
                                                    </div>
                                                </div>
                                            ) : (null)
                                        }

                                        {
                                            this.state.emailerror ? (
                                                <p className="email-error">
                                                    {this.state.emailerror}
                                                </p>
                                            ) : (null)
                                        }
                                        
                                    </div>
                                    
                                    
                                    <div className="d-flex just form-content">
                                        
                                        <div className="input-col">
                                            <input 
                                            type="text" 
                                            name="email" 
                                            placeholder="Email Address"
                                            value={this.state.email}
                                            onChange={ this.handleFormInput } />
                                        </div>
                                        <div className="subscribe">
                                            <button type="submit" className="button primary subscribe-button large">
                                                {
                                                    this.state.sending === true ? ("Sending...") : ("Subscribe")
                                                }
                                                
                                            </button>
                                        </div>
                                      
                                    </div>
                                    

                                    <div className="hp-input-col">
                                            <input 
                                            type="text" 
                                            name="hpname" 
                                            value={this.state.hpname}
                                            onChange={ this.handleFormInput }
                                            />
                                    </div>
                           
                                </form>
                              </div>
                        
                            <div className="respect">
                                <p>We 'll respect your privacy</p>
                            </div>
                        
                        </div>
                                
                       
                    </div>
             
                            
               
            </div>

        </section>
       
    )
    }

  
}

export default Subscribe;
