import React, { useContext, useEffect, useState } from 'react'
import Lottie from 'react-lottie';
import VocihContext from '../../context/vocihContext';
import success from '../../images/success-icon.json'


function DonationSuccess() {

    const appdata  = useContext(VocihContext)
    const [ status, setSatus ] = useState();



    const successLottie = {
        autoplay:true,
        loop:false,
        animationData:success,
        renderSettings: {
            preserveAspectRatio : "xMidYMid slice"
        }
    }



    useEffect(() => {
        setSatus("processing")
        
        
        // Send Donor data to airtable
        // =====================================

            let donorData = {
                "records" : [
                    {
                        "fields" : 
                        {
                            "Firstname": `${appdata.appdata.firstname}`,
                            "Lastname": `${appdata.appdata.lastname}`,
                            "Amount": `${appdata.appdata.donationTotal}` ,
                            "Category" : `${appdata.appdata.category}`,
                            "Organization": `${appdata.appdata.donateAsOrgName}`,
                            "Date": `${appdata.appdata.date}`,
                            "Email": `${appdata.appdata.email}`,
                            "Phone": `${appdata.appdata.phone}`,
                            "Address": `${appdata.appdata.address}`,
                            "Country": `${appdata.appdata.country}`,
                            "Zipcode": `${appdata.appdata.zipCode}`,
                        }
                    }
                ]
            }

            const sendData = JSON.stringify(donorData);

            // send data to airtable
            fetch(`https://cors-anywhere.herokuapp.com/https://api.airtable.com/v0/app7cNniwL76IHtfS/Donations?api_key=${process.env.REACT_APP_AIRTABLE_API_KEY}`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`
                },
                body: sendData
            })
            .then(response => response.json())
            .then(data => {
                setSatus("done")
                console.log(data)

            })
            .catch(err => {
                console.log("Error posting to Airtable", err)
                setSatus("error")
            })

            if(status === "done") {
                appdata.setIsDonationProcessing(false)
            }

           

        // =====================================
        // End airtable
      
    }, []);


    
  


    return (
      
        <section id="donation-success">
            <div className="success-wrapper d-flex justify-content-center align-items-center ">
            <div className="success-message-wrap">
                <div className="title-check">
                    <div className="check-icon">
                    <Lottie 
                            options={successLottie}
                            width={60}
                            height={60}
                            />
                    </div>
                    <div className="success-title">
                        <h2>
                            Donation Succesful
                        </h2>
                    
                    </div>
                </div>

                <div className="thank-you-letter">
                    <div className="letter">
                        <p className="dear">Dear { appdata.appdata.firstname } {appdata.appdata.lastname},</p>
                        <p className="note">
                        We say a big thank you for your <strong>${appdata.appdata.donationTotal} donation</strong>. Your donation was processed successfuly, and  we will be in touch to say thank you.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </section>

    
    )
}

export default DonationSuccess
