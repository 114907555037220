import React from 'react'
import Contact from '../../components/global/Contact'
import Layout from '../Layout'
import PageHeader from '../global/PageHeader'

function ContactUs() {
    return (
        <Layout>
        <section className="contact-us-page">
            <div>
                <PageHeader pageTitle="Get in touch with us" />
                <Contact />
            </div>

                
        </section>
        </Layout>
    )
}

export default ContactUs
