import React, { useContext, useEffect } from 'react'
import Layout from '../Layout'
import VocihContext from '../../context/vocihContext';
import DonationSuccess from '../global/DonationSuccess';
import DonateForm from '../global/DonateForm';
import DonateCheckout from '../global/DonateCheckout';
import { HashLink } from 'react-router-hash-link';
import vocihLogo from '../../images/vocih-logo.svg'




const Donate = () => {

    const appdata = useContext(VocihContext)


     //get the category query from the URL
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const category = params.get('category');



    useEffect(() => {
        appdata.setActiveDonateTab("form")
        appdata.setIsDonationComplete(false);
        
            window.scrollTo(0, 0);
        
    },[]);




    

         return (
        <Layout>
            

            <section id="heading">
                <div className="boxed-wrapper">
                    <div className="content d-flex">
                        <div className="logo">
                        <HashLink to="/#home-header"
                                    scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'start' })}>
                                        <img src={vocihLogo} width="145" alt="VOCIH"/>

                                </HashLink>
                        </div>

                      
                    </div>
                </div>
            </section>


            {
                appdata.appdata.isDonatationComplete === false && !appdata.appdata.isDonatationSuccess ? (
                    <section id="tabs">
                        <div className="tabs d-flex justify-content-center align-items-center">
                            <div 
                            className={appdata.appdata.activeDonateTab==="form" ? ("tab preference-billing d-flex active") : ("tab preference-billing d-flex")} >
                                <div className="number-step d-flex justify-content-center align-items-center ">
                                    1
                                </div>
                                <div className="tab-title d-flex justify-content-center align-items-center">
                                    Donation Preference
                                </div>
                            </div>
                            <div className="tab line-bar d-flex justify-content-center align-items-center">
                                <div className="bar"></div>
                            </div>
                            <div 
                            className={appdata.appdata.activeDonateTab==="checkout" ? ("tab confirm-donate d-flex active") : ("tab confirm-donate d-flex")} 
                           >
                                <div className="number-step d-flex justify-content-center align-items-center">
                                    2
                                </div>
                                <div className="tab-title d-flex align-items-center">
                                    Confirm and Donate
                                </div>
                            </div>
                        </div>
            </section>
                ): (null)
            }
         

            {
                appdata.appdata.activeDonateTab === "form" ? (
                    <DonateForm 
                    category={category}

                     />
                ) : (null)
            }

            { appdata.appdata.isDonatationComplete === false && appdata.appdata.activeDonateTab === "checkout" ? (
                <DonateCheckout />
                ) : (null)
            }

            { appdata.appdata.isDonationSuccess === false ? (
                <DonateCheckout />
                ) : (null)
            }



            { appdata.appdata.isDonatationComplete === true ? (
                <DonationSuccess />
                ) : (null)
            }

           


            

        </Layout>
    )
    

   
}




export default Donate


