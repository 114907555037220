import React from 'react'
import LogoNav from './LogoNav'
import PageHeaderBG from '../../images/page-header-1.png'

function PageHeader(props) {
    return (
        <section id="page-header" 
        style={{ backgroundImage: `${PageHeaderBG}` }}>
           
            <div className="logo--nav">
                 <LogoNav />
            </div>
           
            <div className="boxed-wrapper">
                    <div className=" d-flex align-items-end title-content">
                        <div className="page-title">
                            <h1>{props.pageTitle}</h1>
                        </div>
                        
                    </div>
              
            </div>
        </section>
    )
}

export default PageHeader
