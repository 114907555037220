import React from 'react';
import { Switch, Route } from 'react-router-dom' 
import Home from './components/pages/Home';
import Donate from './components/pages/Donate';
import ContactUs from './components/pages/ContactUs';



function App() {
  return (
    <>

      <Switch>
          <Route>
              <Route exact path="/" component={Home} />
              <Route path="/donate" component={Donate} />
              <Route path="/contact" component={ContactUs} />
          </Route>
      </Switch>
    </>
  );
}

export default App;
