import React, { Component } from 'react'
import SocialIcons from './SocialIcons';
import Lottie from 'react-lottie';
import SuccessIcon from '../../images/success-icon.json';
import { MdErrorOutline } from 'react-icons/md';
import { IconContext } from 'react-icons';


class ContactForm extends Component {


    state = {
        firstname: "",
        lastname: "",
        email: "",
        message: "",
        hpfield:"",
        fnameError: null,
        lnameError: null,
        emailError: null,
        msgError: null,
        airtableError: null,
        status: "Send",
    }

    

    handleFormInput = (e) => {
        e.preventDefault();
        const fieldname = e.target.name;
        this.setState( 
            {
               ...this.state,
                [e.target.name] : e.target.value
            })
        if(fieldname === "firstname") {
            this.setState(prevState => ({
                ...prevState,
                fnameError: "",
                status: "Send"
            }))
        }

        else if(fieldname === "lastname") {
            this.setState(prevState => ({
                ...prevState,
                lnameError: "",
                status: "Send"
            }))
        }


        else if(fieldname === "email") {
            this.setState(prevState => ({
                ...prevState,
                emailError: "",
                status: "Send"
            }))
        }
        else if(fieldname === "message") {
            this.setState(prevState => ({
                ...prevState,
                msgError: "",
                status: "Send"
            }))
        }
    }

    handleFormSubmit = (e) => {
        e.preventDefault();

        //check if contact foeld is empty
        if(this.state.firstname === ""){
            this.setState(prevState => ({...prevState, fnameError: "First name is Required"}))
            
        }
        if(this.state.lastname === "") {
            this.setState(prevState => ({...prevState, lnameError: "Last name is Required"}))
        
        }
        if(this.state.email === "") {
            this.setState(prevState => ({...prevState, emailError: "Email is Required"}))
        }
        // check if email is right
        const re = /\S+@\S+\.\S+/;
        if(this.state.email.length > 0 && !re.test(this.state.email)){
            this.setState(prevState => ({...prevState, emailError: "Check your email and try again"}))
        }

        if(this.state.message === "") {
            this.setState(prevState => ({...prevState, msgError: "Message is Required"}))
        } 

        

        // check to ensure the spam honeypot field is empty 
        // Check to ensure other fields are ok
        if(this.state.hpfield === "" && this.state.fnameError === "" && this.state.lnameError === "" && this.state.emailError === "" && this.state.msgError === "" ){

            console.log("everything is good");

            this.setState(prevState => ({...prevState, status:"Sending..."}));

            const dataToAirtable = 
            {
                "records": [
                    {
                        "fields" : 
                        {
                            "Firstname": `${this.state.firstname}`,
                            "Lastname": `${this.state.lastname}`,
                            "Email": `${this.state.email}`,
                            "Message": `${this.state.message}`
                        }
                    }
                ]
            }
    
            const data = JSON.stringify(dataToAirtable);
    
            // send to Airtable
            fetch(`https://cors-anywhere.herokuapp.com/https://api.airtable.com/v0/app7cNniwL76IHtfS/Contact%20Us?api_key=${process.env.REACT_APP_AIRTABLE_API_KEY}`, 
            {
                method : "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`
                },
                body : data
            })
            .then(response => response.json())
            .then(data => {
                this.setState(prevState => ({
                    ...prevState,
                    status: "Message Sent",
                    firstname: "",
                    lastname: "",
                    email: "",
                    message: ""
                }))
                
            }).catch(err => {
                    console.log(err);
                    this.setState({ airtableError : true, status: "falied" })
                })



        }// if hpfield empty


       
      
    }//handle submit


    render() {

        const LottieOptions = {
            loop: false,
            autoplay: true,
            animationData: SuccessIcon,
            renderSettings : {
                preserveAspectRatio : "xMidYMid slice"
            }
        };


    return (
        <section id="contact-block">
            <div className="boxed-wrapper">

                
                <div className="content d-flex">

                <div className="left-content">
                    <div className="title">
                    <h3>Contact Us</h3>
                    <p> Feel free to contact us with any questions</p>
                    </div>

                    <div className="email">
                        <h6>
                            Email
                        </h6>
                        <p>info@vocih.org</p>
                    </div>

                  
                       
                        <div className="social">
                            <SocialIcons  />
                        </div>
                        
                    </div>


                    <div className="right-content">

                    {
                        this.state.status === "Message Sent" ? (
                        <div className="success-message-div d-flex align-items-center">
                            <div className="status-icon">
                                <Lottie
                                options = {LottieOptions}
                                height = {32}
                                width = {32} />
                            </div>
                            <div className="status-message">
                            Thank You. We’ve received your message!
                            </div>
                        </div>
                        ) : (null)
                    }

                        
                        
                        {
                        this.state.airtableError === true ? (

                            <div className="server-error d-flex align-items-center justify-content-between">
                                <div className="error-icon d-flex align-items-center justify-content-center">
                                <IconContext.Provider value={{ size: "24px",color: "#FFFFFF" }}>
                                        <MdErrorOutline />
                                    </IconContext.Provider>
                                
                                </div>
                                <div className="error-content">
                                We encoutered some errors. Please Try Again
                                </div>
                            </div>
                            ) : (null)
                        }

                        <form onSubmit={this.handleFormSubmit}>
                        <div className="form-row firstlast">
                          <div className="firstname">

                            <div className="label-wrap d-flex justify-content-between">
                              <label htmlFor="firstname">First name</label>
                              
                              {
                                  this.state.fnameError !== "" ? (
                                  <label name="error firstname-error" className="error">{this.state.fnameError}</label>
                                  ) : (null)
                              }
                             
                            </div>

                              <input 
                              type="text" 
                              name="firstname" 
                              id="firstname" 
                              className="firstname"
                              value={this.state.firstname}
                              onChange={this.handleFormInput}/>
                          </div>


                          <div className="lastname">
                            <div className="label-wrap d-flex justify-content-between">
                              <label htmlFor="lastname">Last name</label>
                              

                              {
                                this.state.lnameError !== "" ? (
                                <label name="error lastname-error" className="error">{this.state.lnameError}</label>
                                ) : (null)
                              }

                            </div>
                              <input 
                              type="text" 
                              name="lastname" 
                              id="lastname" 
                              className="lastname"
                              value={this.state.lastname}
                              onChange={this.handleFormInput}/>
                          </div>
                        </div>

                        <div className="form-row">
                        <div className="email">
                                <div className="label-wrap d-flex justify-content-between">
                                     <label htmlFor="email">Email Address</label>

                                    {
                                        this.state.emailError !== "" ? (
                                        <label name="error email-error" className="error">{this.state.emailError}</label>
                                        ) : (null)
                                    }
                                </div>
                             
                              <input 
                              type="text" 
                              name="email" 
                              id="email" 
                              className="email"
                              value={ this.state.email }
                              onChange={this.handleFormInput}/>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="message">
                            <div className="label-wrap d-flex justify-content-between">
                              <label htmlFor="message">Message</label>


                              {
                                this.state.msgError !== "" ? (
                                <label name=" error message-error" className="error">{this.state.msgError}</label>
                                ) : (null)
                              }
                              </div>

                              <textarea 
                              name="message"
                               id="" cols="30" 
                               rows="10" 
                               className="message"
                               value={this.state.message}
                               onChange={this.handleFormInput}
                               ></textarea>
                          </div>
                         
                        </div>

                        <div className="hp-row">
                        <input 
                              type="text" 
                              name="hpfield" 
                              id="hpfield" 
                              className="hpfield"
                              value={this.state.hpfield}
                              onChange={this.handleFormInput} />
                        </div>

                        <div className="form-row submit-row d-flex">
                            <button 
                            type="submit" 
                            className="primary large">
                                {
                                    this.state.status
                                }
                                
                              
                            </button>

                            
                        </div>
                        
                        
                        </form>
                    </div>


                 
                </div>
            </div>
        </section>
           
    )
    }
}


export default ContactForm
