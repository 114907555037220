import React, {  useRef, useEffect, useContext} from 'react'
import VocihContext from '../../context/vocihContext';


function PayWithPaypal(props) {

    const appdata = useContext(VocihContext)
    const { items, total } = props;
    const paypalRef = useRef();











useEffect(() => {
    
  

// Initiate Paypal payement
// =====================================
        window.paypal
        .Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        intent: 'CAPTURE',
                        purchase_units: [{
                            description: `VOCIH Donation - ${items}`,
                            amount: {
                                currency_code: 'USD',
                                value: total
                            }
                        }]
                    })
                }, 
                onApprove: async(data, actions) => {
                    const order = await actions.order.capture();
                    appdata.setIsDonationProcessing(true)
                    appdata.setIsDonationComplete(true)
                    appdata.setDonationSuccess(true)
                    
                },
                onError: err => {
                    appdata.setDonationSuccess(false);
                    console.error('ERROR', err)
                }
        }).render(paypalRef.current)
        }, [items, total])
// =====================================
// End Paypal
    









    return (
     
 
        <div ref={paypalRef}/>


    )

    
}

export default PayWithPaypal
