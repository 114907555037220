import React, { useState, useContext, useEffect } from 'react'
import CountryList from '../global/Countries';
import VocihContext from '../../context/vocihContext';



function DonateForm(props) {

    const appdata = useContext(VocihContext)

    // Donate Info
    const [ donationAmt, setDonationAmt ] = useState(0);
    const [ otherAmtPref, setOtherAmtPref ] = useState(false);
    const [ processFeeOptin, setProcessFeeOptin ] = useState();
    const [ processingFee, setProcessingFee ] = useState(0);
    const [ donationTotal, setDonationTotal ] = useState(0);
    const [ donateAsOrgPref, setDonateAsOrgPref ] = useState();
    const [ donateAsOrgName, setDonateAsOrgName ] = useState("");

    // Billing Data
    const [ firstname, setFirstname ] = useState("");

    const [ lastname, setLastname ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ phone, setPhone ] = useState("");
    const [ addressLine1, setAddressLine1 ] = useState("");
    const [ addressLine2, setAddressLine2 ] = useState("");
    const [ country, setCountry ] = useState("");
    const [ zipCode, setZipCode ] = useState("");

    // Error
    const [ amtError,setAmtError ] = useState();
    const [ otherAmtPrefError, setOtherAmtPrefError ] = useState();
    const [ processFeeOptinError, setProcessFeeOptinError ] = useState();
    const [ donateAsOrgPrefError, setDonateAsOrgPrefError ] = useState();
    const [ donateAsOrgNameError, setDonateAsOrgNameError ] = useState();
    const [ firstnameError, setFirstnameError] = useState();
    const [ lastnameError, setlastnameError ] = useState();
    const [ emailError, setEmailError] = useState();
    const [ phoneError, setPhoneError ] = useState();
    const [ addressError, setAddressError] = useState();
    const [ countryError, setCountryError] = useState();
    const [ zipCodeError, setZipCodeError ] = useState();

    const [ categoryDesc, setCategoryDesc] = useState()

    // Error Flag
    const [ isError , setIsError ] = useState()



    function handleSubmit (e){
        e.preventDefault();
        
        
        // 1. Amount
        if(!donationAmt) {
            setAmtError("A donation amount is required");
            setIsError(true);
        }
        // 2. Other Amount
        if(otherAmtPref && !donationAmt) {
            setOtherAmtPrefError("You chose to enter a custom amount. A custom donation amount is required");
            setIsError(true);
        }
        // 3. processing fee
        if(processFeeOptin === undefined) {
            setProcessFeeOptinError("Select if or not you'd like to cover processing fee")
            setIsError(true);
        }
        // 4. donate as org
        if(!donateAsOrgPref) {
            setDonateAsOrgPrefError("Select if you like to donate as individual or organization")
            setIsError(true);
        }
        // 5. org name
        if(donateAsOrgPref === "yes" && donateAsOrgName === "") {
            setDonateAsOrgNameError("Organization name is required")
            setIsError(true);
        }
        // 7. firstname
        if(!firstname) {
            setFirstnameError("First name required")
            setIsError(true);
        }
        // 8. lastname
        if(!lastname) {
            setlastnameError("Last name required")
            setIsError(true);
        }
        // 9. email
        if(!email) {
            setEmailError("Email required")
            setIsError(true);
        }
        const re = /\S+@\S+\.\S+/;
        if(email && !re.test(email)){
            setEmailError("Email isn't right")
            setIsError(true);
        }
        // 10. phone
        if(!phone) {
            setPhoneError("Phone required")
            setIsError(true);
        }
        const phoneregex = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm
        if(phone && !phoneregex.test(phone)) {
            setPhoneError("phone isnt right")
            setIsError(true);
        }
        // 11. address
        if(!addressLine1) {
            setAddressError("Address required")
            setIsError(true);
        }
        // 12. Country
        if(!country) {
            setCountryError("Country required")
            setIsError(true);
        }
        // 13. Zipcode
        if(!zipCode) {
            setZipCodeError("Zipcode required")
            setIsError(true);
        }

        const zipregex = /[0-9a-zA-Z]+/gm
        if(zipCode && !zipregex.test(zipCode)) {
            setZipCodeError("Zipcode isn't right")
            setIsError(true);
        }


         
       

        else if((amtError, otherAmtPrefError, processFeeOptinError, donateAsOrgNameError, donateAsOrgPrefError, firstnameError, lastnameError, emailError, phoneError, addressError, countryError, zipCodeError) === "")
        {
           const address = `${addressLine1} ${addressLine2}`;
           const today = new Date().toLocaleDateString()
           const donatecategory = donationCategory(props.category)

            appdata.setActiveDonateTab("checkout")
            appdata.setDonationData(donationAmt, processingFee, donationTotal,processFeeOptin, donateAsOrgPref, donateAsOrgName, firstname, lastname, email, phone, address, country, zipCode, donatecategory, today)
 
        }
      
        
    }
    


    //handle input when an amount option is pressed
    function onChangeDonationAmt (e)
    {
        setOtherAmtPref(false)
        setDonationAmt(parseInt(e.target.value));
        setProcessingFee(
            Number.parseFloat((((donationAmt * 2.9) / 100) + 0.33).toFixed(2)))
            setAmtError("")
            setIsError()
    }
    function handleOtherAmtPref(e)
    {
        setOtherAmtPref(!otherAmtPref);
        setOtherAmtPrefError("")
        setAmtError("")
        setIsError()
    }

    // handle input when custom amount is entered
    function handeCustomDonationAmount (e)
    {
        setOtherAmtPref(true)
        setDonationAmt(parseInt(e.target.value));
        setOtherAmtPrefError("")
        setAmtError("")
        setIsError()
    }
    // function to handle processing optin
    function handleProcessFeeOptIn (e) 
    {
        if(e.target.id === "yes-fee")
        {
            setProcessFeeOptin(true)
            setProcessFeeOptinError("")
            setIsError()
           
        }
        else if (e.target.id === "no-fee") 
        {
            setProcessFeeOptin(false)
            setProcessFeeOptinError("")
            setIsError()
        
        }
    }
    // function to handle donate as org option
    function handleDonateAsOrgPref (e)
    {
        setDonateAsOrgPref(e.target.value)
        setDonateAsOrgPrefError("")
        setIsError()
    }
    //function to handle donate organisation name
    function onChangeDonateOrgName(e) {
        setDonateAsOrgName(e.target.value)
        setDonateAsOrgNameError("")
        setIsError()
    }

    function onChangefname (e) {
        setFirstname(e.target.value);
        setFirstnameError("")
        setIsError()
    }
    function onChangelname (e) {
        setLastname(e.target.value);
        setlastnameError("")
        setIsError()
    }
    function onChangeEmail (e) {
        setEmail(e.target.value);
        setEmailError("")
        setIsError()
    }
    function onChangePhone (e) {
        setPhone(e.target.value);
        setPhoneError("")
        setIsError()
    }
    function onChangeAddress1 (e) {
        setAddressLine1(e.target.value);
        setAddressError("")
        setIsError()
    }
    function onChangeAddress2 (e) {
        setAddressLine2(e.target.value);
        setAddressError("")
        setIsError()
    }
    function onChangeCountry (e) {
        setCountry(e.target.value);
        setCountryError("")
        setIsError()
    }
    function onChangeZip (e) {
        setZipCode(e.target.value);
        setZipCodeError("")
        setIsError()
    }

    function calcDonationTotal (e)
    {
        let total;
        if(processFeeOptin===true) {
            total = parseFloat(donationAmt + processingFee);
        }
       
        else if(!processFeeOptin) 
        {
            total = parseFloat(donationAmt);
        }
        return total
    }

    function donationCategory (donateCat)
    {
        switch(donateCat) {
            case "sac": 
            return ("Sponsor A child Program");
            case "vocih":
                return("VOCIH Missions Fund");
            default:
                return("VOCIH Missions Funds");
        }
    }
   

    useEffect(() => {
       if(props.category === "sac")
        {
            setDonationAmt(38); 
            setProcessingFee(
                Number.parseFloat((((donationAmt * 2.9) / 100) + 0.33).toFixed(2)))
            setDonationTotal(parseFloat(donationAmt + processingFee).toFixed(2))
        }
        
    }, [props.category, donationAmt, processingFee, donationTotal]);

    useEffect(() => {
        setCategoryDesc(donationCategory(props.category))
    }, [props.category, categoryDesc]);


    useEffect(() => {
        if(donationAmt === 0)
        {
            setProcessingFee(0);
            
        }
        else if(donationAmt > 0 && processFeeOptin) {
             setProcessingFee(
            Number.parseFloat((((donationAmt * 2.9) / 100) + 0.33).toFixed(2)))
            setDonationTotal(donationAmt + processingFee) 
        }
        else if(donationAmt !== 0 && !processFeeOptin) {
            setProcessingFee(0)
            setDonationTotal(donationAmt + processingFee)
        }

       
       
    }, [donationAmt, processingFee, processFeeOptin, donationTotal]);



   

    return (
   
        <section id="donate-content-wrap">
            <form onSubmit={handleSubmit}>
            <div className="boxed-wrapper d-flex justify-content-center wrap">
            
            
            <div className="d-flex donate-section">
                     <div className="donate-section-wrap">
                    
                            
                        <div className="donation-info-container">

                         

                            {
                                props.category !== "sac"? (
                                <>
                                    <h5 className="tab-title">
                                        Select Amount
                                    </h5>

                                    <div className="d-flex justify-content-between error-wrap">

                                    {
                                        amtError ? (
                                        <span className="error">{amtError}</span>
                                        ) : (null)
                                    }

                                    {
                                        otherAmtPref && !amtError  ? (
                                        <span className="error">{otherAmtPrefError}</span>
                                        ) : (null)
                                    }
                                    </div>
                                    
                                    <div className="donation-amount">
                                        <div className="d-flex flex-wrap selectAmt">
                                        <button
                                        value="5"
                                        type="button"
                                        className={ otherAmtPref=== false && donationAmt === 5 ? "amt active" : "amt" }
                                        onClick={onChangeDonationAmt}
                                        >$5</button>
                                        <button 
                                        value="10"
                                        type="button"
                                        className={ otherAmtPref=== false && donationAmt === 10 ? "amt active" : "amt" }
                                        onClick={onChangeDonationAmt}
                                        >$10</button>
                                        <button 
                                        value="15"
                                        type="button"
                                        className={ otherAmtPref=== false && donationAmt === 15 ? "amt active" : "amt" }
                                        onClick={onChangeDonationAmt}
                                        >$15</button>
                                        <button 
                                        value="20"
                                        type="button"
                                        className={ otherAmtPref=== false && donationAmt === 20 ? "amt active" : "amt" }
                                        onClick={onChangeDonationAmt}
                                        >$20</button>
                                        <button 
                                        value="25"
                                        type="button"
                                        className={ otherAmtPref=== false && donationAmt === 25 ? "amt active" : "amt" }
                                        onClick={onChangeDonationAmt}
                                        >$25</button>

                                        <button 
                                        value="other"
                                        type="button"
                                        className={ otherAmtPref ? "amt active" : "amt" }
                                        onClick={handleOtherAmtPref}
                                        >Other</button>
                                    </div>

                                        { otherAmtPref ? (
                                            <div className="other">
                                            <label htmlFor="customAmt" className="other-label">Enter other Amt</label>
                                            <div className="d-flex textfield">
                                                <div className="dollar d-flex align-items-center justify-content-center">$</div>
                                                <div className="input">
                                                    <input 
                                                    type="number"
                                                    onKeyUp={ handeCustomDonationAmount }
                                                    name="custom_amt"
                                                    id="customAmt"/>
                                                </div>
                                            </div>
                                        </div>
                                        ) : (null)}
                                    </div>
                                  
                                    </>

                                ) : (null)
                            }
                            

                            <div className="donation-pref-options">
                                <h5 className="tab-title">
                                    Donation preferences
                                </h5>

                            </div>


                            <div className="cover-processing-fee">
                                <p className="pref-label">I’d like to cover the processing fee so 100% of my donation goes to Voice of Christ</p>

                                <div className="d-flex justify-content-between error-wrap">
                                    <span> 
                                        {
                                            processFeeOptinError  ? (
                                            <span className="error">{processFeeOptinError}</span>
                                            ) : (null)
                                        }
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between cover-button-options">
                                    <button 
                                    className={ processFeeOptin === false ? "switch process-no active" : "switch process-no"}
                                    name="no-fee"
                                    type="button"
                                    id="no-fee"
                                    onClick={ handleProcessFeeOptIn }
                                    >No. Not Today Please</button>
                                    
                                    <button 
                                    className={ processFeeOptin ? "switch process-yes active" : "switch process-yes"}
                                    name="yes-fee"
                                    type="button"
                                    id="yes-fee"
                                    onClick={ handleProcessFeeOptIn }
                                    >Yes. I’ll cover it</button>
                                </div>
                            </div>

                            <div className="org-donation">
                                <p className="pref-label">I’ll like to make this donation as an organization</p>
                                <div className="d-flex justify-content-between error-wrap">
                                    <span> 
                                        {
                                            donateAsOrgPrefError  ? (
                                            <span className="error">{donateAsOrgPrefError}</span>
                                            ) : (null)
                                        }
                                    </span>

                                    <span> 
                                        {
                                            donateAsOrgNameError  ? (
                                            <span className="error">{donateAsOrgNameError}</span>
                                            ) : (null)
                                        }
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between org-button-options">
                                    
                                    <button 
                                    name="no"
                                    className={ donateAsOrgPref === "no" ? "switch org-btn-no active" : "switch org-btn-no"}
                                    value="no"
                                    type="button"
                                    onClick={handleDonateAsOrgPref}
                                    >No</button>

                                    <button 
                                    className={ donateAsOrgPref === "yes" ? "switch org-btn-yes active" : "switch org-btn-yes"}
                                    name="yes"
                                    value="yes"
                                    type="button"
                                    onClick={handleDonateAsOrgPref}
                                    >Yes</button>
                                </div>

                                {
                                    donateAsOrgPref === "yes" ? (
                                        <div className="org-name">
                                        <label htmlFor="org-name-text">Organization Name</label>
                                        <input type="text" name="org-name" 
                                        id="org-name"
                                        value={donateAsOrgName}
                                        onChange={onChangeDonateOrgName} className=""/>
                                        </div>
                                    ) : (null)
                                }
                            
                        </div>




                        </div>


                        <div className="donation-billing-wrapper">

                            <div className="billing-tab-title">
                                <h5 className="tab-title">
                                    Billing
                                </h5>
                            </div>
                        
                            <div className="billing-form-content">
                                                    
                                    <div className="form-row">
                                        <div className="d-flex flex-column form-col firstname">
                                            <div className="d-flex justify-content-between label-error">
                                                 <label htmlFor="firstname">Firstname</label>
                                                {
                                                    firstnameError  ? (
                                                    <span className="error">{firstnameError}</span>
                                                    ) : (null)
                                                }
                                            </div>
                                            <input 
                                            type="text"
                                            name="firstname"
                                            value={firstname}
                                            onChange={ onChangefname }/>
                                        </div>

                                        <div className="d-flex flex-column form-col lastname">
                                            <div className="d-flex justify-content-between label-error">
                                            <label htmlFor="lastname">Lastname</label>
                                            {
                                                lastnameError  ? (
                                                <span className="error">{lastnameError}</span>
                                                ) : (null)
                                            }
                                            </div>
                                            <input 
                                            type="text" 
                                            name="lastname"
                                            value={lastname}
                                            onChange={ onChangelname }/>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="d-flex flex-column form-col email">
                                            <div className="d-flex justify-content-between label-error">
                                            <label htmlFor="email">Email Address</label>
                                            {
                                                emailError  ? (
                                                <span className="error">{emailError}</span>
                                                ) : (null)
                                            }
                                            </div>
                                            <input 
                                            type="email" 
                                            name="email"
                                            value={email}
                                            onChange={ onChangeEmail }/>
                                        </div>

                                        <div className="d-flex flex-column form-col phone">
                                            <div className="d-flex justify-content-between label-error">
                                            <label htmlFor="text">Phone Number</label>
                                            {
                                                phoneError  ? (
                                                <span className="error">{phoneError}</span>
                                                ) : (null)
                                            }
                                            </div>
                                            <input
                                            type="text"
                                            name="phone"
                                            value={phone}
                                            onChange={ onChangePhone }/>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="d-flex flex-column form-col-full address">
                                            <div className="d-flex justify-content-between label-error">
                                            <label htmlFor="email">Address</label>
                                            {
                                                addressError  ? (
                                                <span className="error">{addressError}</span>
                                                ) : (null)
                                            }
                                            </div>
                                            <input 
                                            type="text" 
                                            name="address1"
                                            className="address1"
                                            value={addressLine1}
                                            onChange={ onChangeAddress1 }
                                            placeholder="Address Street 1"/>

                                            <input 
                                            type="text" 
                                            name="address2"
                                            className="address2"
                                            value={addressLine2} 
                                            onChange={ onChangeAddress2 }
                                            placeholder="Addtess Street 2"/>
                                        </div>

                                        
                                    </div>
                                    <div className="form-row">
                                            <div className="d-flex flex-column form-col country">
                                                <div className="d-flex justify-content-between label-error">
                                                <label htmlFor="country">Country</label>
                                                {
                                                countryError  ? (
                                                <span className="error">{countryError}</span>
                                                ) : (null)
                                                }
                                                </div>
                                                <select 
                                                type="text"
                                                value={country}
                                                onChange={ onChangeCountry }
                                                name="country">
                                                    <option value="Select"></option>
                                                    <option value=""></option>
                                                    

                                                    {
                                                        CountryList.map(country => (
                                                        <option value={country} key={country}>{country}</option>
                                                        ))
                                                    }
                                                    
                                                </select>
                                            </div>

                                            <div className="d-flex flex-column form-col zip-code">
                                                <div className="d-flex justify-content-between label-error">
                                                <label htmlFor="zipCode">Zip/Postal Code</label>
                                                {
                                                zipCodeError  ? (
                                                <span className="error">{zipCodeError}</span>
                                                ) : (null)
                                                }
                                                </div>
                                                <input 
                                                type="text"
                                                value={zipCode}
                                                onChange={ onChangeZip }
                                                name="zipCode"/>
                                            </div>
                                        </div>


                                </div>
                        
                            
                        </div>

                    

                        


                    </div>
                   
            </div>
               
            <div className="summary-section">
                <div className="summary-title">
                    <h5>Summary</h5>
                </div>

                <div className="summary-section-wrap">
                      
                    <div className="donation-category-wrap">
                        
                        <div className="inner">
                            <div className="pre-category">
                                <p>Donation going towards</p>
                            </div>
                            
                            <div className="category-text">
                                <h3>
                                    { donationCategory (props.category) }
                                    
                                </h3>
                            </div>
                            <div className="sumfreq">
                                
                                        One-Time
                                
                            </div>
                        </div>
                       
                    </div>

                    <div className="summary-box">
                            <div className="donation-summary">
                                <div className="donation-subtotal">
                                <div className="d-flex justify-content-between frequency-subtotal">
                                    <span className="freq-caption">
                                        <span>Giving
                                        </span> 
                                    </span>

                                    <span className="sub-figure">
                                        ${ donationAmt }
                                    </span>
                                </div>
                                <div className="description">
                                    <span>
                                    Thank you for your donation today. All Payments are encrypted and secure.
                                    </span>
                                </div>
                            </div>

                            { processFeeOptin ? (
                                 <div className="donation-process-fee">
                                    <div className="d-flex justify-content-between processing">
                                        <span className="processing-caption">
                                            Processing Fee
                                        </span>

                                        <span className="sub-figure">
                                            +${ processingFee }
                                        </span>
                                    </div>
                                </div>
                            ) : (null)}
                               

                                <div className="d-flex justify-content-between grand-total">
                                    <div className="total-caption">
                                        <span >
                                            Total
                                        </span>
                                    
                                    </div>

                                    <div className="total-figure">
                                        <span className="currency">USD</span>
                                            <span className="total-amt">
                                                ${ calcDonationTotal() }
                                            </span>
                                    </div>
                                        
                                    
                                    
                                </div>
                             

                            </div>

                                
                            
                                        <div className="continue-btn">
                                        {
                                            isError ? (
                                                <div className="continue-error">
                                                    You have some errors. Please check and try again
                                                </div>
                                            ) : (null)
                                        }
                                            <button 
                                            type="submit"
                                            className="primary large"
                                            
                                            >
                                                Continue
                                            </button>
                                        </div>

                                
                             
                           

                        </div>
                
                </div>
                    
                 
            
            </div>
        </div>
    
        </form>
       
        </section>
      
    )
}

export default DonateForm
