import React, { Component } from 'react'
import SocialIcons from './SocialIcons'
import { HashLink } from 'react-router-hash-link';
import vocihLogo from '../../images/vocih-logo.svg'


class Footer extends Component{


    render ()
    {
          return (
        <section id="footer">
             <div className="boxed-wrapper footer-container">
           

                    <div className="f-content-wrap d-flex justify-content-between ">
                       
                           <div className="vocih-footer-logo-box">
                            <div className="vocih-logo">
                                <HashLink to="/#home-header"
                                    scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                                        <img src={vocihLogo} width="145" alt="VOCIH"/>

                                </HashLink>
                                </div> 
                               
                           </div>

                           
                           <div className=" d-flex justify-content-between footer-right-content">

                               <div className="footer-menu links1">
                                   <h5>About</h5>
                                   <ul>
                                       <li>
                                           
                                           <HashLink to="/#how_we_do_it"
                                            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                                        >About Us</HashLink>
                                       </li>
                                       <li>
                                          
                                           <HashLink to="/#vocih-pillars"
                                            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                                        >Our Pillars</HashLink>
                                       </li>
                                       <li>
                                        
                                           <HashLink to="/#mission_vision"
                                            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                                        >Mission and Vision</HashLink>
                                       </li>
                                   </ul>
                               </div>

                               <div className="footer-menu links2">
                                   <h5>
                                       Engage
                                   </h5>
                                    <ul>
                                        <li>
                                        
                                           <HashLink to="/#sponsor-child"
                                            scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'start' })}
                                        >Sponsor A Child</HashLink>
                                       </li>
                                       <li>
                                           
                                           <HashLink to="/contact#page-header"
                                            scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'start' })}
                                        >Contact Us</HashLink>
                                       </li>
                                       <li>
                                           
                                           <HashLink to="/donate#heading"
                                            scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'start' })}
                                        >Donate</HashLink>
                                       </li>
                                   </ul>
                               </div>


                               <div className="footer-social d-flex align-items-end">
                                    <SocialIcons size="1rem" color="#040404" width="100px" />
                               </div>
                              
                           </div>
                           

                       
                        
                     
                    
                    </div>
           
                     
                <div className="d-flex justify-content-md-between copyright-legal"> 
                   
                        <div className="copyrights">
                                <p>© 2020. Voice of Christ in Haiti. All RIghts Reserved.
                                </p>
                        </div>
                    
                </div>
            </div>

        </section>
       
    )
    }

  
}

export default Footer;
