import React, { useContext } from 'react'
import { NavLink, Link } from 'react-router-dom'
import VocihContext from '../../context/vocihContext';
import HamburgerMenu from './HamburgerMenu';

function MobileNav() {

    const  appdata = useContext(VocihContext)

    return (
        <section id="mobile-menu"
        className={appdata.appdata.isMobileMenuOpen ? "active" : ""}
        >
            <div className="boxed-wrapper mobile-menu-wrap">
                <div className="top-bar d-flex justify-content-between" >
                    <div className="d-flex align-items-center menu-caption">
                        
                        <div className="menu-text">MENU</div>
                    </div>

                    <div>
                        <HamburgerMenu bgColor="#040404"/>
                    </div>
                    
                        
                    
                </div>
                <div className="nav-wrap d-flex flex-column align-content-between">

                    <div className="links-wrap">
                            
                            <div className="d-flex menu-links">
                                <ul>
                                    <li>
                                        <NavLink 
                                        exact
                                        to="/" 
                                        activeClassName="activeMenuLink" 
                                        onClick={appdata.toggleMenu}
                                        >
                                            Home</NavLink>
                                    </li>
                                   
                        
                                    <li>
                                        <NavLink 
                                        exact
                                        to="/contact"
                                        onClick={appdata.toggleMenu}
                                        activeClassName="activeMenuLink" 
                                        >
                                            Contact Us</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    
                    <div className="footer">
                        <div className="cta">
                            <div className="title">
                                <h6>
                                Join the Cause. Support Vocih
                                </h6>
                            </div>
                        
                            <div className="footenote-btn">
                                <Link to="/donate" onClick={appdata.toggleMenu} className="button primary large" >Donate</Link>
                            </div>
                        </div>

                        

                    </div>
                
                
                </div>
            </div>
        </section>
    )
}

export default MobileNav
