import React from 'react';

import { Link } from 'react-router-dom';


function DonateButton(props) {
   

    return (
     
        <div className="donate-btn">
                <Link
                className={props.classlist}
                to={"/donate?category="+props.category}
                >{props.text}</Link>
        </div>




      
    )
}

export default DonateButton
