import React from "react"
import Layout from "../Layout"
import CallToAction from '../global/CallToAction';
import HomeHeader from '../global/HomeHeader';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import MapImage from '../../images/haiti-map.png'
import image1 from '../../images/home-gallery-image1.png'
import image2 from '../../images/home-gallery-image2.png'
import image3 from '../../images/home-gallery-image3.png'
import image4 from '../../images/home-gallery-image4.png'
import image5 from '../../images/home-gallery-image5.png'
import image6 from '../../images/home-gallery-image6.png'
import image7 from '../../images/home-gallery-image7.png'
import image8 from '../../images/home-gallery-image8.png'
import image9 from '../../images/home-gallery-image9.png'
import image10 from '../../images/home-gallery-image10.png'
import image11 from '../../images/home-gallery-image11.png'
import image12 from '../../images/home-gallery-image12.png'


import Contact from "../global/Contact";
import DonateButton from "../global/DonateButton";
import { Tween } from 'react-gsap';




function Home() {

   


    return (
       
                <Layout>
                    <HomeHeader />

                    <section id="how_we_do_it">
                        <div className="boxed-wrapper">

                            <div className="hwdi-content d-flex justify-content-between">
                                <div className="hwdi-content-title">
                                    <h3>
                                    Our mission is to make the voice of Christ the loudest in all of Haiti.
                                    </h3>
                                </div>
                                <div className="hwdi-content-copy">
                                    <p>
                                    Voice of Christ in Haiti (VOCIH) is a <span className="emph">501c3 nonprofit organization</span> committed to breaking the chains of poverty and voodoo that have influenced our country for far too long. VOCIH provides an environment where families can learn about the gospel and seek biblical guidance on their journey with Christ.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </section>
                
        

                  



                    <section id="vocih-pillars">
                        <div className="boxed-wrapper">
                            <div className="vocih-pillar-wrap">

                              
                                <Tween from={{ opacity: '0', y:"-200px"}} duration={1.2} ease="back.out(1.7)">
                                    <div className="vocih-pillars-title">
                                    <p className="overline">
                                        Our Core Pillars
                                    </p>
                                    <h2>
                                    Our heart is spreading the Love of God in Haiti
                                     
                                    </h2>
                                    <p className="subtext">Haiti needs Gods Love</p>
                                </div>
                                </Tween>
                                <div className="pillars d-flex justify-content-between">
                                    
                                    <div className="pillar ltr">
                                        <div className="pillar-content">
                                            <div className="pillar-title">
                                           
                                                <h3>
                                               Evangelism
                                               
                                                </h3> 
                                            </div>
                                            <div className="text-cta-wrap">
                                                <div className="pillar-copy">
                                                    <p>
                                                    For over 200 years, Haiti has been under the demonic cloud of voodoo. Spreading the gospel of Jesus Christ is the primary focus of our ministry and everything we do! Our church is the spiritual home to more than 300 members of the community. We believe that Jesus Christ is our Lord and Savior, and through him, all things are possible!

                                                    </p>
                                                
                                                </div>
                                                
                                            </div>  
                                        </div>
                                       
                                    
                                    </div>

                                    <div className="pillar ltr">
                                        <div className="pillar-content">
                                            <div className=" pillar-title">
                                           
                                            <h3>
                                               Education
                                                </h3> 
                                                <h3>
                                               
                                                </h3> 
                                            </div>
                                            <div className="text-cta-wrap">
                                                <div className="pillar-copy">
                                                    <p>
                                                    Haiti has one of the lowest literacy rates in the world at 61%. We believe that education is a vital piece of bringing change to the nation. Voice Of Christ Evangelical School has over 250 elementary and middle school aged students and is the primary source of education for children in the community. Studying the word of God is part of our daily curriculum. We are able to minister to both children and parents within the community.
                                                    </p>
                                                
                                                </div>
                                               

                                            </div>  
                                        </div>
                                       
                                    
                                    </div>
                                    <div className="pillar ltr">
                                        <div className="pillar-content">
                                            <div className=" pillar-title">
                                          
                                                <h3>
                                               Health
                                              
                                                </h3> 
                                              
                                            </div>
                                            <div className="text-cta-wrap">
                                                <div className="pillar-copy">
                                                    <p>
                                                    In Haiti, there is only one doctor for every 25,000 people. In most rural areas, like where we are, there is no healthcare available. VOCIH’s First Aid office is the only formal healthcare facility within hours. Our local community and children are able to come a have wounds and illnesses treated.  Each and every patient gets the opportunity to accept Jesus Christ as their lord and savior.

                                                    </p>
                                                
                                                </div>
                                               

                                            </div>  
                                        </div>
                                       
                                    
                                    </div>

                                </div>
                             
   
                            </div>
                        </div>
                    </section>
            


                    <section id="mission_vision">
                        <div className="boxed-wrapper">
        
                           
        
                            <div className="mv_content_wrap d-flex align-items-center">
                            
                                <div className="mv_content">

                                    <div className="mission-vision">
                                        <div className="title">
                                            <h3>Our Mission</h3>
                                        </div>
                                        <div className="copy">
                                            <p>Our mission is to empower the current and future leaders of Haiti to be a light and advocate for change, in their own communities, by living out the word of God daily and using education to rise above poverty. </p>
                                        </div>
                                    </div>
        
                                    
                                    <div className="mission-vision">
                                        <div className="title">
                                            <h3>Our Vision</h3>
                                        </div>
                                        <div className="copy">
                                            <p>Our vision is to transform rural communities in Haiti by creating disciples who will multiply the body of Christ in Haiti, while increasing opportunities for children to receive a good education and healthcare. </p>
                                        </div>
                                    </div>

                                   
                                </div>
                            
                                <div className="d-flex justify-content-end haiti_map_wrap">
                                    
                                    <div className="map-image">
                                        <img src={MapImage} alt="" width="750" className="haiti-map"/>
                                    </div>

                                    <div className="hq-pin-card">

                                        <div className="overline">
                                            <h6 className="overline">
                                            Headquaters
                                            </h6>
                                        </div>
                                        <div className="hq">
                                            <span className="hq-city">
                                                Platana, Haiti
                                            </span>
                                            <span className="hq-where">
                                            Six hours from the capital city
                                            </span>
                                            
                                        </div>


                                    </div>
                                       
                                    <div className="pins">
        
                                        
            
                                            <div
                                            className="d-flex align-items-center justify-content-sm-center pin pin1"
                                           
                                            >
                                                <div className="pin-inner">
                                                </div>
                                            </div>
            
            
                                        </div>
                                 
                                    
                                </div>
                            </div>
        

                         
                    
                        </div>  
                    </section>
        


                    <section id="sponsor-child">
                        <div className="boxed-wrapper">

                            <div className="row sac-container">
                                <div className="d-flex align-items-center">
                                    

                                    <div className="content">
                                      

                                        <div className="main-callout">
                                            <h3>
                                            Sponsor a child with as little as&nbsp; 
                                            <span style={{color: "#DCBFFF"}}>$38/month</span>
                                            </h3>
                                        </div>
                                        <div className="sac-description">
                                            <p>
                                            Our school serves 250 students from various parts of Platana, Haiti. Many of our students are living in absolute poverty. Your donation will help provide a student with clothing, school supplies, daily vitamins, and a warm meal. For $38 a month, you can help a child receive nourishment physically and intellectually, forever changing their trajectory in life.
                                            </p>
                                        </div>
                                        
                                        <div className="sac-donate-btn">
                                                <DonateButton 
                                                category="sac"
                                                text="Sponsor A Child"
                                                classlist="button primary medium" />
                                            </div>
                                    </div>

                                </div>
                            
                                
                            
                            </div>

                            

                        </div>
                    </section>
        


                    <section id="gallery">
                        <div className="boxed-wrapper">

                                <div className="gallery_content_top">

                                   
                                    <div className="title">
                                        <h3>
                                       Mission Gallery
                                        </h3>
                                    </div>
                                    
        
                                  
                                </div>
                            
                        </div>

                        <div className="sm-wrapper masonry-images-content">
                            <div className="masonry-container">

                                <OwlCarousel items={4}
                                className="owl-theme"
                                nav
                                loop
                                autoplay
                                lazyLoad
                                autoplayHoverPause
                                autoplaySpeed={200}
                                margin={8}
                                dots={false}
                                responsive={{
                                    1440: {
                                        items: 3,
                                        nav:true,
                                    },
                                    1024: {
                                        items: 3,
                                        nav: true,
                                    },
                                    960: {
                                        items: 2,
                                        nav: true,
                                    }
                                }}
                                >
                                    <div className="image-box"><img src={image1} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image2} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image3} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image4} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image5} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image6} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image7} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image8} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image9} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image10} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image11} alt=""/> </div>

                                    <div className="image-box"><img className="gallery-img" src={image12} alt=""/> </div>

                                    


                                </OwlCarousel>
                                
                            </div>
                        </div>
                    
                    </section>
                
        
                    <section id="call_to_action">
                        <CallToAction/>
                    </section>


                    <section>
                       
                        <Contact />
                    </section>
                


                             
             
                     </Layout>
       
    )
}

export default Home
