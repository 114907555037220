

import React, { Component } from "react"
import PropTypes from "prop-types"
import Footer from './global/Footer';
import '../sass/main.scss'
import MobileNav from "./global/MobileNav";
import VocihContext from "../context/vocihContext";
import Subscribe from "./global/Subscribe";




class Layout extends Component {

  static contextType = VocihContext;
 
  render(){
    const { appdata } = this.context;
      return (
      <div>  
        <div className="mainMenu">
        <MobileNav />
        </div>
        {
          appdata.isMobileMenuOpen ? (
            <div className="modal-overlay">
            </div> ): (null)
        }
        <main>{this.props.children}</main>
        <Subscribe />
        <Footer />
      </div>
    )
  }
    
    }

 
          
           
    



Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


