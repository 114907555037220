import React, { Component } from 'react'
import VocihContext from '../../context/vocihContext';



export class HamburgerMenu extends Component {
    static contextType = VocihContext;

    state = {
        isMobileMenuOn: false
    }

    handleMobileMenuOpen = () =>
    {
        let appdata = this.context;
        appdata.toggleMenu();
        
    }

    componentDidUpdate() {
        let appdata = this.context;
        if(appdata.appdata.isMobileMenuOpen) {
             document.querySelector('body').classList.add("fixed-no-scroll") 
           
           
        }
        if(!appdata.appdata.isMobileMenuOpen) {
             document.querySelector('body').classList.remove("fixed-no-scroll") 
         
        }
      
    }


    render() {
        let appdata = this.context;
        return (
            <div 
            className={ appdata.appdata.isMobileMenuOpen ? "mobile-nav active" : "mobile-nav" }
            >
                <button 
                onClick={ this.handleMobileMenuOpen }
                className="d-flex align-items-center" >
                    <div className="menu-hamburger">
                      <div className="bar" style={{ backgroundColor: `${this.props.bgColor}`}}></div>
                      <div className="bar" style={{ backgroundColor: `${this.props.bgColor}`}}></div>
                      <div className="bar" style={{ backgroundColor: `${this.props.bgColor}`}}></div>
                    </div>
                
                </button>
            </div>
        )
    }
}

export default HamburgerMenu
