import React from 'react';
import DonateButton from '../global/DonateButton';


export default function CallToAction(props) {
    return (
        <section id="call-to-action">
            <div className="boxed-wrapper ">
                     

                            <div className="d-flex justify-content-between align-items-center cta-content">
                                <div className="cta-section-title ">
                                    <h3>
                                    Help us to break the chains of poverty and empower the next generation
                                    </h3>
                                </div>

                                <div className="d-flex justify-content-end CTA-button-wrap">
                                    <DonateButton 
                                    category="vocih" 
                                    text="Donate"
                                    classlist="button outline-reverse large"
                                    />
                                        
                                    
                                </div>
                            </div>
                        
                
                        
                
                    </div>
        </section>
        
        )
}
