import React, { useContext } from 'react';
import PayWithPaypal from '../global/PayWithPaypal';
import VocihContext from '../../context/vocihContext';
import Lottie from 'react-lottie';
import success from '../../images/success-icon.json'
import failed from '../../images/falied.json';



function DonateCheckout(props) {
    const appdata = useContext(VocihContext)



    const failedLottie = {
        autoplay:true,
        loop:false,
        animationData:failed,
        renderSettings: {
            preserveAspectRatio : "xMidYMid slice"
        }
    }

    const successLottie = {
        autoplay:true,
        loop:false,
        animationData:success,
        renderSettings: {
            preserveAspectRatio : "xMidYMid slice"
        }
    }
 

    

    return (


        <section id="donation-checkout" >
            <div className="checkout-wrapper d-flex justify-content-center align-items-center">
                <div className="checkout-wrap">
                    <div className="checkout-notification-wrap">
                        
                       {
                           appdata.appdata.isDonationSuccess === false ? ( 

                                <div className="paypal-processing-error d-flex  justify-content-between align-items-center">
                                    <div className="error-icon d-flex align-items-center justify-content-center">
                                        <Lottie 
                                        options={failedLottie}
                                        width={32}
                                        height={32}
                                        />
                                    </div>
                                    <div className="paypal-error-text">
                                        <span>
                                            We encountered an error. Please try again
                                        </span> 
                                    </div>
                                    
                                </div>
                           ): (null)
                        }

                        
                        {
                           appdata.appdata.isDonationSuccess === true && appdata.appdata.isDonationComplete === true ? ( 
                             <div className="paypal-processing-success d-flex justify-content-between align-items-center">
                                <div className=" success-icon d-flex align-items-center justify-content-center">
                                    <Lottie 
                                    options={successLottie}
                                    width={32}
                                    height={32}
                                    />
                                </div>
                                <div className="paypal-success-text">
                                <span>
                                    Donation Successful. Finishing...
                                    </span>
                                </div>
                            </div> 
                           ) : (null)
                        }

               

                    </div>
                  

                    <div className="checkout-summary-box">
                            <div className="checkout-title">
                                <h3>Donation Checkout</h3>
                            </div>
                            <div className="content-box">
                                <div className="checkout-donation-category">
                                    <div className="title">
                                        <span>
                                            { appdata.appdata.category }
                                        </span>
                                        
                                    </div>
                                    <div className="frequency">
                                        One-Time
                                    </div>
                                </div>
                                <div className="giving-box">
                                

                                    <div className="figures">
                                        <div className="giving-figure d-flex justify-content-between">
                                            <span className="caption">Giving</span>
                                            <span className="figure-number">${appdata.appdata.donationAmt}</span>
                                        </div>
                                        <div className="process-fee-figure d-flex justify-content-between">
                                            <span className="caption">Processing Fee</span>
                                            <span className="figure-number">+${appdata.appdata.processingFee}</span>
                                        </div>
                                        <div className="total-figure d-flex justify-content-between">
                                            <span className="caption">Total</span>
                                            <span className="total-number">${appdata.appdata.donationTotal}</span>
                                        </div>
                                    </div>

                                    <div className="paypal-checkout-button">
                                                            
                                            <PayWithPaypal 
                                                total = {appdata.appdata.donationTotal}
                                                items={appdata.appdata.category} 
                                                />
                                                
                                        
                                    </div>  
        
                                
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </section>



        
    )
}

export default DonateCheckout
