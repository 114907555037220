import React, { Component, createContext } from 'react'

export const VocihContext = createContext();


export class VocihContextProvider extends Component {

    state=
    {
        isMobileMenuOpen: false,
        isDonatationComplete: false,
        isDonationSuccess: null,
        isDonationProcessing: null,
        activeDonateTab:"form",
        donationAmt:0,
        processingFee:0,
        donationTotal:0,
        precessFeeOptin:null,
        donateAsOrgPref:null,
        donateAsOrgName:"",
        firstname:"",
        lastname:"",
        email:"",
        phone:"",
        address: "",
        country:"",
        zipCode:"",
        category:"",
        date:""
    }

    setDonationSuccess = (bool) => {
        this.setState((state) => ({
            ...state,
            isDonationSuccess: bool,
        }))
    }
    


    toggleMenu = () =>
    {
        this.setState((state)=>({
            ...state,
            isMobileMenuOpen : !state.isMobileMenuOpen,
        }))
        
    }

    setIsDonationComplete = (bool) => 
    {
        this.setState(state => ({
            ...state,
            isDonatationComplete : bool
        }))
    }

    setIsDonationProcessing = (bool) => {
        this.setState(state => (
            {...state, isDonationProcessing: bool,}))
    }

 

    setActiveDonateTab = (tab) => {
        this.setState(state => ({
            ...state,
            activeDonateTab : tab
        }))
    }



    setDonationData = (
        donationAmt,processingFee,donationTotal, precessFeeOptin,
        donateAsOrgPref,donateAsOrgName,firstname,lastname,email, phone,address,country,zipCode,category, date) => {
            this.setState(state => ({
                ...state,
                donationAmt:donationAmt,
                processingFee:processingFee,
                donationTotal:donationTotal,
                precessFeeOptin:precessFeeOptin,
                donateAsOrgPref:donateAsOrgPref,
                donateAsOrgName:donateAsOrgName,
                firstname:firstname,
                lastname:lastname,
                email:email,
                phone:phone,
                address: address,
                country:country,
                zipCode:zipCode,
                category:category,
                date:date,
            }))
        }


    render () {
          const dataset = this.state;
          return (
            <VocihContext.Provider value={{
                appdata: dataset,
                toggleMenu : this.toggleMenu,
                setIsDonationComplete: this.setIsDonationComplete,
                setActiveDonateTab: this.setActiveDonateTab,
                setDonationData : this.setDonationData,
                setIsDonationProcessing: this.setIsDonationProcessing,
              
                setDonationSuccess: this.setDonationSuccess
               
            }}>
                { this.props.children }
            </VocihContext.Provider>
            ) 
    }
 
}


export default VocihContext;
