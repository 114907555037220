import React from 'react';
import Nav from './Nav';
import vocihLogo from '../../images/vocih-logo.svg'
import { HashLink } from 'react-router-hash-link';


const LogoNav = () => {

    

    
    return (
        
                    <section id="logonav">
                        <div className="d-flex justify-content-between align-items-center boxed-wrapper">
                            <div className="d-flex align-items-center logo">
                              

                                <HashLink to="/#home-header"
                                    scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'start' })}>
                                        <img src={vocihLogo} width="145" alt="VOCIH"/>

                                </HashLink>
                            </div>
                            <Nav/>
                        </div> 
                    
                    </section>
                )
            }
            
        
    


export default LogoNav;
