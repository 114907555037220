import React from 'react';
import { NavLink } from 'react-router-dom';
import DonateButton from './DonateButton';
import SocialIcons from './SocialIcons';
import HamburgerMenu from './HamburgerMenu';

function Nav(props) {



    return (
        
        <div className="d-flex nav-wrapper">
          
            <div className="nav d-flex align-items-center justify-content-end">

                <div className="nav-links-wrap">
                    <ul className="d-flex justify-content-between align-items-center">
                    <li className="page-active d-flex align-content-center">

                        <NavLink exact to="/" activeClassName="page-active">Home</NavLink>
                        
                    </li>
                        
                        <li className="page-active d-flex align-content-center">
                            <NavLink exact to="/contact" activeClassName="page-active">Contact us</NavLink>
                        </li>
                    </ul>
                </div>

                <div className="social-icons-wrap">
                    <SocialIcons color="#FFFFFF" />
                </div>
                
                <div className="donate-btn-wrap"
                >
                <DonateButton 
                category="vocih" 
                text="Donate"
                classlist="button primary medium"
                />
                </div>
                
                <div className="mobile-menu-icon">
                     <HamburgerMenu 
                        bgColor="#FFFFFF"
                        />
                </div>
               

            </div>

            
           
        </div>
                 
           
    )
}




export default Nav
